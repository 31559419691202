<template>
    <div>
        <el-form :model="paramForm" ref="paramForm" label-width="100px" size='medium'>
            <el-form-item label="秘钥Key：">
                <el-input v-model="paramForm.du_parameter1"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default{
    data:function(){
        return{
        }
    },
    props: {
        paramForm: Object,
    },
    created () {

    },
    methods:{
        validateForm () {
            let flag = true
            if (!this.paramForm.du_parameter1) {
                this.$message.error('请输入秘钥')
                flag = false
            }
            return flag
        }
    }
}
</script>


<style></style>